import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Mindmap = _resolveComponent("Mindmap", true)!

  return (_openBlock(), _createElementBlock("div", {
    id: "mindmap",
    onKeydown: _cache[1] || (_cache[1] = _withKeys(() => _ctx.alert(), ["enter"]))
  }, [
    (_ctx.mindmapData)
      ? (_openBlock(), _createBlock(_component_Mindmap, {
          key: 0,
          ref: "test",
          root: _ctx.mindmapData,
          "onUpdate:root": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mindmapData) = $event))
        }, null, 8, ["root"]))
      : _createCommentVNode("", true)
  ], 32))
}