
import Mindmap from "@/components/shared/mindmap/index.vue";
import { NodeType } from "@/components/shared/mindmap/mindmapNodes";
import { supabase } from "@/service/SupabaseService";
import { defineComponent, onMounted, ref } from "@vue/runtime-core";
export default defineComponent({
  components: { Mindmap },
  name: "mindmap",

  setup() {
    const mindmapData = ref({
      data: {
        text: "PROJECT_NAME",
        ticketData: {
          //type: NodeType.PROJECT,
        },
      },
      children: [],
      _updata: true,
    });

    const nest = (items, id = null, link = "parentTicketId") =>
      items
        .filter((item) => item[link] === id)
        .map((item) => ({ data: item, children: nest(items, item.id) }));
    onMounted(() => {
      fetchProjectTickets(1);
      watchTickets(1);
    });
    const watchTickets = (projectId) => {
      supabase
        .from(`tickets:projectId=eq.${projectId}`)
        .on("*", () => {
          fetchProjectTickets(projectId);
        })
        .subscribe();
    };
    const fetchProjectTickets = async (projectId) => {
      let { data } = await supabase
        .from("tickets")
        .select(`id,title, parentTicketId, ticketTypeId(id, name) `)
        .eq("projectId", projectId);
      mindmapData.value.children = nest(
        data?.map((data) => ({
          id: data?.id,
          text: data.title,
          parentTicketId: data.parentTicketId,
          ticketData: {
            type: data.ticketTypeId.name.toUpperCase(),
          },
        }))
      );
    };
    return { mindmapData };
  },
});
